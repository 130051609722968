@use "../../../../../styles/etc/env" as *;
@use "./fonts.scss";

$assetsBaseURL: "#{$s3BaseURL}assets/custom_pages/schedule/";

.boilerplate {

    margin-top: -2rem;
    margin-right: -1rem;
    margin-bottom: -1rem;
    margin-left: -1rem;

    //typography
    --letterspacing_2pt5px: 0.15625rem;
    --12px: 0.75rem;
    --14px: 0.875rem;
    --15px: 0.9375rem;
    --16px: 1rem;
    --17px: 1.0625rem;
    --18px: 1.125rem;
    --19px: 1.1875rem;
    --20px: 1.25rem;
    --21px: 1.3125rem;
    --25px: 1.5625rem;
    --30px: 1.875rem;
    --35px: 2.1875rem;
    --40px: 2.5rem;

    //fonts
    --prak_med: 'Praktika Medium', Arial, Helvetica, sans-serif;
    --prak_bold: 'Praktika Bold', Arial, Helvetica, sans-serif;
    --prak_xtraBold: 'Praktika Extra Bold', Arial, Helvetica, sans-serif;
    --proxima_nova_black: "proxima-nova", sans-serif;

    //colors
    --memphis_midnight: #0C2340;
    --grizzlies_gold: #FFC72C;
    --away_altbeale: #EEF4FC;
    --beale_blue: #7D98C1;
    --grizzlies_grey: #707372;
    --adaBeale_onwhite: #4E7AA6;


    :global {
        @import "./custom.module.scss";

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: var(--prak_xtraBold);
            letter-spacing: var(--letterspacing_2pt5px);
            text-transform: uppercase;
        }

        h1 {
            font-size: 50px;
            font-family: var(--proxima_nova_black);
            font-weight: 900;
            font-style: normal;
            // // background-image: url(https://s3.grizzliesapp.com/assets/custom_pages/season-theme/2023-24/Blue-Galaxy-optimized.jpg);
            // // background-clip: text;
            // // color: transparent !important;
            // // mix-blend-mode: multiply;
            // // background-size: cover;
            // // z-index: 1;

        }

        h2 {
            font-size: var(--25px);
        }

        h3 {
            font-size: var(--20px)
        }

        h4 {
            font-size: var(--16px);
        }

        h5 {
            font-size: var(--14px);
        }

        h6 {
            font-size: var(--12px);
            font-family: var(--prak_bold);
        }

        li {
            font-size: var(--16px);
        }

        p {
            color: var(--memphis_midnight);
            font-size: var(--16px);
            letter-spacing: 0.075rem;
        }

        a {
            font-size: var(--16px);
            color: var(--memphis_midnight) !important;
        }

        a:hover {
            text-decoration: none !important;
        }

        a:focus {
            box-shadow: none !important;
        }

        select {
            color: var(--memphis_midnight);
            font-family: var(--prak_bold);
            letter-spacing: var(--letterspacing_2pt5px);

        }

        select:focus {
            box-shadow: none !important;
        }

        .globalContainer {
            margin: 2.125rem;
        }

        button {
            font-size: var(--14px);
            text-transform: uppercase;
            letter-spacing: var(--letterspacing_2pt5px);
            font-family: var(--prak_xtraBold);
            padding: 10px 5px 10px 5px;
            appearance: none; // Just in case we missed anything.

        }

        .primaryBtn,
        .secondaryBtn {
            width: 100%;
            margin: 5px;
            border: 2px solid var(--memphis_midnight);
            color: var(--memphis_midnight);
            border-radius: 5px;
            text-align: center;
        }

        .primaryBtn {
            background-color: var(--memphis_midnight);
            color: #fff !important;
        }

        .scheduleContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            margin-top: 10px;

        }

        .cyberMonday {
            background-color: #0c2340;

            a {
                img {
                    margin: 0 auto;
                }
            }
        }


        //Section Styling

        section {

            //main Banner
            &.mainBanner {
                display: flex;
                justify-content: space-between;
                align-items: center;

                background-color: #ffffff;
                height: 120px;
                padding: 40px;

                // position: relative;
                // z-index: 10;
                // overflow: hidden;

                // background: url("#{$assetsBaseURL}ConcreteWall_progressive.jpg");
                // background-size: cover;

                // .hoop {
                //     mix-blend-mode: multiply;
                //     width: 400px;
                //     position: absolute;
                //     left: 70%;
                //     transform: translateX(-50%);
                //     top: 0px;
                // }

                //schedule title and select
                .sch_selector {
                    display: flex;
                    align-items: center;

                    .seasonYearsSelector {
                        margin-left: 20px;

                        select {
                            margin-left: 0px;
                            background-color: transparent;
                            border: none;
                            font-size: var(--25px);
                            font-family: var(--prak_xtraBold);

                            // color: white;
                            // --bs-form-select-bg-img: url("#{$assetsBaseURL}MG_Icons_Arrow_Left.svg");
                        }
                    }

                    /*This will work for firefox spacing issue */
                    @-moz-document url-prefix() {
                        select {
                            width: 115%;
                        }
                    }
                }

                .page_sponsor_desktop {
                    >div {

                        // color: white !important;
                        >div {
                            >h6 {
                                font-synthesis: none;
                            }

                            >img {
                                padding-top: 10px;
                                width: 60%;
                                mix-blend-mode: multiply;
                            }
                        }
                    }

                    a:hover {
                        text-decoration: none !important;
                    }

                }

            }

            &.sticky-container {

                z-index: 2 !important;
                margin-top: -70px;
                position: sticky;
                position: -webkit-sticky;
                top: 0;
                background-color: var(--memphis_midnight);
                padding: 10px;
                color: white;

                .sticky-section {
                    margin: 0 auto;
                    width: 50%;

                    .sponsorLogoLink {

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        h6 {
                            margin-right: 10px;
                            color: white;
                        }

                        img {
                            width: 50px;
                        }
                    }

                    a:hover {
                        text-decoration: none !important;
                    }
                }
            }

            //Filters V1 Desktop
            &.filtersContainer {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .upcomingResultsToggle {
                    display: flex;

                    .active {
                        border-bottom: 3px solid var(--beale_blue) !important;
                    }

                    .inactive {
                        color: #D3D3D3;
                    }

                    button {
                        font-size: var(--21px);
                        padding: 0px;
                    }

                    button:last-child {
                        margin-left: 40px;
                    }

                    margin-bottom: 10px;
                }

                .filterPrintCalContainer {
                    display: flex;
                    align-items: center;
                    font-synthesis: none;

                    .filterButtonContainer {
                        display: flex;
                        align-items: center;

                        .offCanvasButton,
                        .filter-active {
                            background-color: #fff;
                            color: var(--memphis_midnight);
                            font-family: var(--prak_bold);
                            font-size: var(--12px);
                            display: flex;
                            border: none;
                            padding: 0px !important;
                            transition: none !important;

                            img {
                                width: 20px;
                                margin-left: 10px;
                            }
                        }

                        .filter-active {
                            display: flex;
                            align-items: center;
                            background-color: var(--grizzlies_gold);
                            padding: 10px 15px 10px 15px !important;
                            border-radius: .5rem;
                            margin-left: 15px;

                            .filter-icon {
                                width: 10px;
                                margin-left: 6px;
                            }
                        }
                    }

                    .printCalContainer {
                        display: flex;

                        .printSchedule,
                        .addToCalendar {
                            display: flex;
                            align-items: center;
                            margin-left: 30px;

                            img {
                                width: 20px;
                                margin-left: 10px
                            }

                            a {
                                font-size: var(--12px);
                            }
                        }
                    }
                }

            }


            //Filter V2 Desktop
            &.ticketFinder {
                text-align: center;

                //Hidden per request
                .ticketFinderTitle {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    gap: 1rem;
                    margin-bottom: 1.5rem;

                    >img {
                        width: 30px;
                    }

                    .primaryBtnFilter {
                        margin-bottom: 0;
                    }
                }

                .activeBtnPlacement {

                    .filter-active {
                        display: flex;
                        align-items: center;
                        background-color: var(--grizzlies_gold);
                        padding: 10px 15px 10px 15px !important;
                        border-radius: .5rem;
                        margin-left: 0;
                        margin-bottom: .5rem;

                        .filter-icon {
                            width: 10px;
                            margin-left: 6px;
                        }
                    }
                }

                .ticketFinderFilter {
                    display: inline-grid;
                    justify-items: center;
                    align-items: center;
                    justify-content: space-evenly;
                    grid-template-columns: 1fr 1fr 1fr;
                    width: 100%;
                    gap: .5rem;

                    //Custom Dropdown
                    >fieldset {
                        position: relative;
                        padding: 14px 15px;
                        border: 2px solid #0c2340;
                        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
                        border-radius: 5px;
                        width: 100%;

                        .filterLabelContainer {
                            display: flex;
                            align-items: center;

                            .selectedLabel {
                                color: var(--adaBeale_onwhite);
                                font-weight: bold;
                                text-transform: uppercase;
                                margin-left: .5rem;
                                display: flex;
                                font-synthesis: none;
                            }
                        }

                        .dropdown {
                            width: calc(100% + 0px);
                            max-height: 300px;
                            text-align: left;
                            overflow-x: hidden;
                            overflow-y: initial;
                            position: absolute;
                            top: 3em;
                            z-index: 500;
                            background-color: #fff;
                            border: 2px solid #0c234022;
                            left: 0;
                            border-radius: 5px;

                            >li {
                                >button {
                                    text-align: left;
                                    width: 100%;
                                    padding: 15px 20px
                                }

                                >button:hover {
                                    background-color: var(--away_altbeale);
                                    width: 100%;
                                }
                            }

                            // #Weekends >button{
                            //     background-color: #0C2340;
                            //     color: #fff;
                            // }
                        }
                    }

                    >fieldset:hover {
                        border: 2px solid #FFC72C;
                    }

                    //Spacer
                    .spacerFilterV2 {
                        height: 1px !important;
                        width: 15px !important;
                        background-color: var(--memphis_midnight);
                        transform: rotate(90deg);
                    }
                }
            }

            //schedule

            &.schedContainer {
                .noUpcomingGamesFallback {
                    color: var(--grizzlies_grey);

                    p {
                        text-transform: none;
                        font-style: italic;
                    }
                }


                // Global Components CSS
                .futureGameCSS,
                .pastGameCSS {
                    .gDiv {
                        border: 2px solid var(--memphis_midnight);
                        padding: 25px;
                        border-left: none;

                        .sznTag {
                            margin-top: 5px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            text-align: center;

                            .lineBreak {
                                height: 2px !important;
                                width: 15% !important;
                                background-color: var(--memphis_midnight);
                                border-radius: 100px;
                                margin: 10px 10px;
                            }

                            .istLabel {
                                color: var(--adaBeale_onwhite);
                                margin-bottom: 5px;
                            }

                            h6:last-child {
                                font-size: 10px;
                                font-family: var(--prak_bold);
                            }
                        }
                    }

                    .gameInfo {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border-left: 2px solid var(--memphis_midnight);
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;

                        h2 {
                            margin-bottom: 10px;
                        }

                        .weekday {
                            margin-bottom: 10px;
                        }

                        .gTime {
                            font-family: var(--prak_bold);
                            margin-bottom: 15px;
                        }

                        .scoreWinLoss {
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }


                    }

                    ///REGULAR SEASON STYLING
                    .homeRegular {
                        background-color: var(--memphis_midnight);
                        color: white;

                        .homeLabelRegular {
                            background-color: white;
                            color: var(--memphis_midnight);
                            padding: 5px;
                            width: 60%;
                            text-align: center;
                            border-radius: 5px;
                        }
                    }


                    .awayRegular {
                        background-color: var(--away_altbeale);
                        color: var(--memphis_midnight);
                        border: 2px solid var(--memphis_midnight);

                        .awayLabelRegular {
                            background-color: var(--memphis_midnight);
                            color: white;
                            padding: 5px;
                            width: 60%;
                            text-align: center;
                            border-radius: 5px;
                        }
                    }

                    ///PLAYOFFS STYLING
                    .homePlayoff {
                        background-color: var(--grizzlies_gold);
                        color: var(--memphis_midnight);
                        border: 2px solid var(--memphis_midnight);

                        .homeLabelPlayoff {
                            background-color: var(--memphis_midnight);
                            color: white;
                            padding: 5px;
                            width: 60%;
                            text-align: center;
                            border-radius: 5px;
                        }
                    }

                    .awayPlayoff {
                        background-color: white;
                        border: 2px solid var(--memphis_midnight);

                        .awayLabelPlayoff {
                            background-color: var(--away_altbeale);
                            padding: 5px;
                            width: 60%;
                            text-align: center;
                            border-radius: 5px;
                        }

                    }

                    .gameOpp {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        // aspect-ratio: 1;

                        .teamLogo {
                            img {
                                width: 80px;
                                margin: 0 auto;
                                // border: 2px red dashed;
                                margin-bottom: 10px;
                            }
                        }

                        .teamName {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            h5 {
                                font-family: var(--prak_med);
                            }
                        }

                    }

                }

                // end of global settings

                //Past Games CSS

                .pastGameCSS {
                    margin-top: 20px;
                    display: grid;
                    grid-template-columns: 1fr 1fr 5fr;
                    margin-bottom: 40px;

                    .resultsGameLeaders {
                        display: grid;
                        align-content: space-evenly;

                        h4 {
                            margin-bottom: 15px;
                        }

                        .primaryBtn {
                            width: initial;
                        }

                        .gameLeaderContainer {
                            display: grid;
                            grid-template-columns: repeat(3, minmax(0, 1fr));
                            gap: 15px;
                            justify-items: center;

                            .player {
                                width: 100%;

                                .rosterImage {
                                    background-color: #E3EAF5;
                                    padding-top: 10px;
                                    border-radius: .5rem;
                                    margin-bottom: 10px;

                                    img {
                                        border-radius: .5rem;
                                        aspect-ratio: 1.368421052631579 / 1;
                                    }

                                    span {
                                        &.lazy-load-image-loaded {
                                            display: inline !important;
                                        }
                                    }
                                }

                                h5 {
                                    margin-bottom: 5px;
                                    text-align: center;

                                }
                            }

                            h6 {
                                margin-bottom: 10px;
                                text-align: center;
                            }

                        }
                    }

                    .resultsGameContainer {
                        display: grid;
                        grid-template-columns: 1fr 2fr;

                        .resultsVideos {

                            .videoTitle {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            }

                            .gametrackerLink {
                                display: flex;

                                img {
                                    margin-left: 10px;
                                }
                            }

                            /*Swiper styles*/

                            overflow: hidden;

                            .swiper-outer {
                                height: 100%;
                                user-select: none;
                                border-radius: .5rem;
                                overflow: hidden;

                                .swiper {
                                    height: 100%;

                                    .swiper-wrapper {
                                        padding: 0;

                                        .swiper-slide {
                                            display: grid;
                                            align-items: center;

                                            .videoPlaceholder {
                                                position: relative;
                                                padding-bottom: 56.25%;
                                                border-radius: .5rem;
                                                overflow: hidden;
                                                display: flex;

                                                video {
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                                    bottom: 0;
                                                    right: 0;
                                                    // border-radius: .5rem;
                                                    z-index: 1;
                                                    // object-fit: cover !important;
                                                    // aspect-ratio: 16/9;
                                                }
                                            }


                                        }
                                    }

                                    .swiper-button-prev,
                                    .swiper-button-next {
                                        // background-color: white;
                                        color: white;
                                        // border-radius: 5px;
                                        // padding: 20px;

                                    }

                                    .swiper-button-prev:after,
                                    .swiper-button-next:after {
                                        font-size: 25px;
                                        text-shadow: rgb(0, 0, 0) 0px 0 7px;
                                    }

                                    .swiper-button-disabled {
                                        pointer-events: initial;
                                    }

                                }

                                // background-color: pink;

                                .videoImagePlaceholder {
                                    position: relative;
                                    display: flex;
                                    border-radius: .5rem;
                                    overflow: hidden;

                                    .playIcon {
                                        position: absolute;
                                        background-color: hsla(211, 68%, 15%, 0.714);
                                        -webkit-backdrop-filter: blur(2px);
                                        backdrop-filter: blur(2px);

                                        border-radius: 50%;
                                        height: 3.75rem;
                                        width: 3.75rem;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        display: grid;
                                        justify-items: center;
                                        align-items: center;
                                        pointer-events: none !important;
                                        z-index: 10;

                                        img {
                                            height: 1.25rem;
                                            width: 1.25rem;
                                            margin-left: 0.25rem;
                                        }
                                    }

                                    img.thumbnail {
                                        // border-radius: .5rem;
                                        aspect-ratio: 16/9;
                                    }

                                    .lazy-load-image-background {
                                        display: block !important;
                                    }

                                    .lazy-load-image-background.blur {
                                        &:not(.lazy-load-image-loaded) {
                                            aspect-ratio: 16/9;
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            /*eof Swiper styles*/

                            .ifnoVideo {
                                display: grid;
                                place-content: center;
                                // height: 100%;
                                background-color: #D3D3D3;
                                margin-top: 20px;
                            }
                        }
                    }
                }



                //Future Games CSS
                .futureGameCSS {
                    margin-top: 20px;
                    display: grid;
                    grid-template-columns: 1fr 1fr 4fr 1fr;
                    margin-bottom: 40px;

                    //broadcast
                    .gameBroadPromosContainer {
                        display: flex;

                        .tuneInTitle_mobile {
                            display: none;
                        }

                        .gameBroadcast {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding: 0;
                            flex: 1 1 0%;

                            .tuneInBlock {
                                padding: 25px;

                                div {
                                    display: flex;
                                    justify-content: flex-start;

                                    ul {
                                        display: flex;
                                        align-items: center;

                                        li {
                                            margin-left: 20px;

                                            img {

                                                height: 40px;
                                            }
                                        }
                                    }
                                }

                                //TV
                                .tv {
                                    align-items: center;
                                    margin-bottom: 20px;

                                    //ESPN LOGO Fix
                                    .ESPN {
                                        img {
                                            width: 80px !important;
                                            height: auto;
                                        }
                                    }

                                    //BALLYS LOGO FIX
                                    .Bally {
                                        img {
                                            width: 280px;
                                            height: auto;
                                        }
                                    }

                                    .gcm {
                                        img {
                                            width: 60px !important;
                                            height: auto;
                                        }
                                    }
                                }

                                .listen {
                                    align-items: center;

                                    img {
                                        width: 200px;
                                        height: 35px;
                                        object-fit: scale-down;
                                        object-position: left;
                                    }

                                    // .espnRadioFallbackImg{
                                    //     width: 150px;
                                    // }

                                    //SiriusXM LOGO FIX
                                    .SiriusXM {
                                        img {
                                            width: 125px;
                                            height: auto;
                                        }
                                    }
                                }
                            }

                            .promoBlock {
                                display: flex;
                                justify-content: flex-start;
                                align-items: baseline;
                                border-top: 2px solid var(--memphis_midnight);
                                padding: 25px;

                                p {
                                    margin-left: 1rem;
                                }
                            }
                        }

                        //theme night and game sponsor block
                        .gameThemePartner {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            flex-basis: 25%;
                            text-align: center;

                            .themeNightTitle {
                                width: 82%;
                                margin: 0 auto;
                                // padding-bottom: 5px;
                            }

                            .lineBreak {
                                height: 2px;
                                width: 15%;
                                background-color: var(--memphis_midnight);
                                border-radius: 100px;
                                margin: 10px 10px;
                            }

                            div {
                                text-align: center;

                                h6 {
                                    margin-bottom: 10px;
                                }

                                img {
                                    width: 8rem;
                                    margin: auto;
                                }
                            }

                        }

                        //Uniform Block

                        .gameUniform {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            flex-basis: 18%;
                            text-align: center;

                            .jerseyImg {
                                margin-bottom: 10px;
                                width: 4rem;
                                height: auto;

                                img {
                                    aspect-ratio: 1/1.25;
                                    object-fit: cover;
                                    object-position: top;
                                }
                            }
                        }
                    }

                    //CTA Buttons Block
                    .gameCTAButtons {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-bottom-right-radius: 5%;
                        border-top-right-radius: 5%;

                        .primaryBtn {
                            margin-bottom: 1rem;
                            color: var(--memphis_midnight);

                        }
                    }
                }

                //end of mapped data css
            }
        }

        //section end

        //// end of global
    }

}



// MOBILE BREAKPOINT
@media (max-width: 767px) {
    .boilerplate {

        :global {

            h1 {
                font-size: 45px;
            }

            h2 {
                font-size: var(--20px);
            }

            h4 {
                font-size: var(--16px);
            }

            h5 {
                font-size: var(--14px);
            }

            h6 {
                font-size: var(--12px);
                font-family: var(--prak_bold);
            }


            .globalContainer {
                margin: 1.125rem;
            }

            section {

                //main Banner
                &.mainBanner {
                    display: grid;
                    align-content: center;
                    height: 250px;
                    padding: 60px 20px 60px 20px;

                    // .hoop {
                    //     mix-blend-mode: multiply;
                    //     width: 300px;
                    //     position: absolute;
                    //     left: 50%;
                    //     transform: translateX(10%);
                    //     top: -1px;
                    // }

                    div {
                        color: var(--memphis_midnight);
                    }

                    //schedule title and select
                    .sch_selector {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .seasonYearsSelector {
                            margin-left: 0px;
                            margin-top: 10px;

                            select {
                                padding-left: 0px;
                            }
                        }

                    }

                    .page_sponsor_desktop {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        margin-top: 15px;

                        >div {
                            >div {
                                >img {
                                    width: 150px;
                                    margin: auto;
                                }
                            }
                        }
                    }
                }


                &.sticky-container {
                    display: none !important;
                }

                // Filter V1 Mobile
                &.filtersContainer {
                    display: flex;
                    flex-direction: column-reverse;


                    .upcomingResultsToggle {
                        display: none;
                        justify-content: space-between;
                        width: 100%;
                        margin-top: 15px;

                        button {
                            font-size: var(--21px);
                        }

                        button:last-child {
                            margin-left: 0px;
                        }

                    }

                    .filterPrintCalContainer {
                        display: none;
                        width: 100%;
                        margin: 10px 0px 10px 0px;

                        .filterButtonContainer {
                            width: 100%;


                            .offCanvasButton {
                                justify-content: flex-start;
                                align-items: center;
                                // border-radius: 0;

                                border: 2px solid #0C2340;
                                width: 100%;
                                padding: .75rem !important;

                                img {
                                    transform: scale(1.2);
                                }
                            }

                            .filter-active {}
                        }

                        .printCalContainer {
                            width: 30%;
                            justify-content: flex-end;


                            .printSchedule,
                            .addToCalendar {
                                margin-left: 10px;

                                h6 {
                                    display: none;
                                }

                                img {
                                    transform: scale(1.2);
                                }
                            }
                        }
                    }

                }

                //Filter V2 Mobile

                &.ticketFinder {
                    text-align: left;

                    .ticketFinderTitle {
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        gap: 1rem;
                        margin-bottom: 1.5rem;

                        >img {
                            width: 30px;
                        }

                        .primaryBtnFilter {
                            margin-bottom: 0;
                        }
                    }

                    .ticketFinderFilter {

                        display: grid;
                        grid-template-columns: 1fr;
                        justify-items: center;
                        gap: .5rem;

                        .ticketFinderTitle {
                            flex-direction: column;
                        }

                        .spacerFilterV2 {
                            display: none !important;
                        }

                        //Custom Dropdown Mobile
                        >fieldset {
                            position: relative;
                            padding: 14px 15px;
                            border: 2px solid #0c2340;
                            box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
                            border-radius: 8px;
                            width: 100%;

                            .filterLabelContainer {
                                display: flex;
                                align-items: center;

                                .selectedLabel {
                                    color: var(--adaBeale_onwhite);
                                    font-weight: bold;
                                    text-transform: uppercase;
                                    margin-left: .5rem;
                                    display: flex;
                                    font-synthesis: none;
                                }
                            }

                            .dropdown {
                                width: calc(100% + 4px);
                                left: -2px;
                                top: 2.7em;

                                >li {
                                    >button {}

                                    >button:hover {}
                                }

                            }
                        }

                        >fieldset:hover {
                            border: 2px solid #0C2340!important;
                        }
                    }

                    .activeBtnPlacement {
                        width: 100%;

                    }



                }

                //schedule

                &.schedContainer {

                    .futureGameCSS,
                    .pastGameCSS {
                        .gDiv {
                            border: 2px solid var(--memphis_midnight);
                            padding: 20px;
                            border-top: none;

                        }

                        .gameInfo {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            border-top: 2px solid var(--memphis_midnight);
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 0;
                            padding: 25px;

                            h2 {
                                margin-bottom: 10px;
                            }


                            .gtime {
                                font-family: var(--prak_bold);
                                margin-bottom: 15px;
                            }
                        }

                        ///REGULAR SEASON STYLING
                        .homeRegular {
                            .homeLabelRegular {
                                width: 100%;
                            }
                        }


                        .awayRegular {
                            .awayLabelRegular {
                                width: 100%;
                            }
                        }

                        ///PLAYOFFS STYLING
                        .homePlayoff {
                            .homeLabelPlayoff {
                                width: 100%;
                            }
                        }

                        .awayPlayoff {
                            .awayLabelPlayoff {
                                width: 100%;
                            }

                        }


                        .gameOpp {
                            border-top-right-radius: 5%;
                            border-bottom-right-radius: 0;
                            border-top: 2px solid var(--memphis_midnight);
                            border-left: none;
                            // aspect-ratio: 1/1;


                            .teamLogo {
                                width: 80px;

                                img {
                                    margin-bottom: 0;
                                }
                            }

                            .teamName {
                                display: flex;
                                flex-direction: column;
                                text-align: center;

                                // margin-top: 10px;
                                h5 {
                                    font-family: var(--prak_med);
                                }
                            }

                        }

                        // end of gameDetails Container

                    }

                    .pastGameCSS {
                        margin-top: 20px;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        text-align: center;

                        //for past game only
                        .gDiv:last-child {
                            border-bottom-right-radius: 10px;
                            border-bottom-left-radius: 10px;
                        }

                        .resultsGameContainer {
                            display: grid;
                            grid-column-start: 1;
                            grid-column-end: 3;
                            grid-template-columns: 1fr;
                        }

                        .resultsVideos {

                            .videoTitle {
                                justify-content: center !important;
                                align-items: center !important;
                                margin-bottom: 15px;
                            }

                            .gametrackerLink {
                                display: none !important;
                            }


                            /*Swiper styles*/

                            overflow: hidden;

                            .swiper-outer {
                                height: auto !important;

                                .swiper {
                                    height: auto !important;

                                }

                                .videoImagePlaceholder {

                                    .playIcon {

                                        border-radius: 50%;
                                        right: 5%;
                                        bottom: 10%;

                                        img {
                                            width: 15px;
                                        }

                                    }

                                }
                            }

                            /*eof Swiper styles*/
                        }
                    }


                    .futureGameCSS {
                        margin-top: 20px;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        text-align: center;

                        //broadcast
                        .gameBroadPromosContainer {
                            display: flex;
                            flex-wrap: wrap;
                            grid-column-start: 1;
                            grid-column-end: 3;

                            border-bottom-right-radius: 10px;
                            border-bottom-left-radius: 10px;
                            border-top: none !important;
                            border: 2px solid var(--memphis_midnight);

                            .gameBroadcast {
                                display: flex;
                                flex-direction: column;
                                padding: 0px;
                                flex: 1 1 0%;
                                order: 2;
                                border: none;

                                .tuneInTitle_mobile {
                                    display: inline;
                                    padding: 20px 0px 10px 0px;
                                }

                                .tuneInBlock {
                                    padding: 0px 15px 20px 0px;
                                    display: flex;
                                    overflow: auto;
                                    align-items: center;

                                    div {
                                        flex: 0 0 auto;
                                        display: grid;

                                        h5 {
                                            display: none !important;
                                        }

                                        li {
                                            width: auto;

                                        }

                                    }

                                    div:first-child {
                                        margin-bottom: 0;
                                    }

                                    .tv {
                                        // order: 2;

                                        img {
                                            width: 150px;
                                            height: 50px;
                                            object-fit: contain;
                                        }

                                        //BALLYS LOGO FIX
                                        .FanDuel {
                                            img {
                                                width: 200px;
                                            }
                                        }

                                        .NBA {
                                            img{
                                                width: 60px;
                                            }
                                        }

                                        .FDSNSE-MEM\/WMC-TV {
                                            img {
                                                width: 280px;
                                            }
                                        }

                                    }

                                    .listen {
                                        h4 {
                                            display: none;
                                            text-align: center;
                                        }

                                        img {
                                            width: 150px;
                                            height: 40px;
                                            object-fit: contain;
                                        }

                                    }
                                }

                                .promoBlock {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    border-top: 2px solid var(--memphis_midnight);
                                    padding: 15px;


                                    h5 {
                                        display: none !important;
                                    }

                                    p {
                                        margin-left: 0;
                                    }

                                }
                            }

                            //theme night and game sponsor block
                            .gameThemePartner {
                                padding: 0px;
                                flex-basis: 100%;
                                order: 1;
                                border-top: none;
                                border-left: none;
                                border-right: none;

                                .themeNightTitle {

                                    padding: 15px;

                                    //font update to match mockup
                                    color: var(--memphis_midnight);
                                    text-transform: capitalize;
                                    font-family: var(--prak_med);
                                    letter-spacing: 0.075rem;
                                    font-size: var(--16px);
                                }

                                .lineBreak {
                                    margin: 0;
                                    width: 6%;
                                }

                                //sponsor div
                                div {

                                    padding: 15px;

                                    h6 {
                                        margin-right: 0px;
                                        margin-bottom: 10px;
                                    }

                                    img {
                                        width: 8rem;
                                    }
                                }

                            }

                            //Uniform Block

                            .gameUniform {
                                order: 4;
                                display: flex;
                                flex-direction: row;
                                // align-items: center;
                                // justify-content: center;
                                flex-basis: 100%;
                                border-top: 2px solid var(--memphis_midnight);
                                border-bottom: none;
                                border-left: none;
                                border-right: none;
                                padding: 0 !important;
                                align-items: center;
                                padding: 35px;

                                .jerseyImg {
                                    width: 50%;
                                    margin-bottom: 0;
                                    overflow: hidden;
                                    position: relative;
                                    padding: 3rem;

                                    img {
                                        position: absolute;
                                        top: -26px;
                                        left: 0;
                                        width: 100%;
                                    }
                                }

                                .uniTitle {
                                    width: 50%;

                                    h6 {
                                        //font update to match mockup
                                        color: var(--memphis_midnight);
                                        text-transform: capitalize;
                                        font-family: var(--prak_med);
                                        letter-spacing: 0.075rem;
                                        font-size: var(--16px);
                                        // inline-size: 58%;
                                        // overflow-wrap: break-word;
                                        margin: 0 auto;
                                        padding: 15px;
                                    }
                                }
                            }
                        }

                        //CTA Buttons Block
                        .gameCTAButtons {
                            order: 10;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;
                            grid-column-start: 1;
                            grid-column-end: 3;
                            border: none;
                            padding: 0px;

                            .primaryBtn {
                                margin: 10px 0 10px 0;
                                color: var(--memphis_midnight);
                            }

                            .secondaryBtn {
                                margin: 10px 0 10px 0;
                            }
                        }
                    }

                    //end of mapped data css


                }

            }

            //section end
        }
    }
}

// TABLET BREAKPOINT

@media (min-width: 768px) and (max-width: 1399px) {
    .boilerplate {
        :global {


            .globalContainer {
                margin: 2.125rem;
            }

            section {

                //main Banner
                &.mainBanner {
                    // height: 40vh;
                    background-size: cover;
                    background-position: 25% 75%;
                    align-content: center;

                    //schedule title and select
                    .sch_selector {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .seasonYearsSelector {
                            margin-left: 0px;
                            margin-top: 0px;


                            select {
                                padding-left: 0px;

                            }
                        }

                    }

                    .page_sponsor_desktop {
                        div {
                            >div {
                                >img {
                                    // width: 15%;
                                }
                            }
                        }
                    }
                }

                ///filters
                &.filtersContainer {

                    display: flex;
                    justify-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    .upcomingResultsToggle {
                        order: 2;
                        margin-top: 15px;
                        margin-bottom: 0;
                        width: 100%;
                        justify-content: space-between;
                    }

                    .filterPrintCalContainer {
                        width: 100%;
                        justify-content: space-between;
                        display: none;

                    }

                }

                //schedule


                &.schedContainer {
                    .noAPIInfo {
                        border-top: 2px solid var(--away_altbeale);
                        color: var(--grizzlies_grey);
                        padding: 30px;
                        font-style: italic;

                        h4 {
                            font-family: var(--prak_med) !important;
                        }
                    }

                    // Global Components CSS
                    .futureGameCSS,
                    .pastGameCSS {
                        .gDiv {
                            border: 2px solid var(--memphis_midnight);
                            padding: 25px;

                            .sznTag {
                                margin-top: 5px;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                text-align: center;

                                .lineBreak {
                                    height: 2px !important;
                                    width: 100% !important;
                                    background-color: var(--memphis_midnight);
                                    border-radius: 100px;
                                    margin: 10px 10px;
                                }

                                .istLabel {
                                    color: var(--adaBeale_onwhite);
                                    margin-bottom: 5px;
                                }

                                h6:last-child {
                                    font-size: 10px;
                                    font-family: var(--prak_bold);
                                }
                            }
                        }

                        .gameInfo {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            border: 2px solid var(--memphis_midnight);
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;


                            h2 {
                                margin-bottom: 10px;
                            }

                            .gtime {
                                font-family: var(--prak_bold);
                                margin-bottom: 15px;
                            }

                            .gscore {
                                margin-top: 10px;

                                span {
                                    margin-left: 10px;
                                }

                                .win {

                                    color: var(--grizzlies_gold);
                                }

                                .loss {

                                    color: var(--grizzlies_grey);

                                }
                            }

                        }

                        .gameOpp {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .teamLogo {
                                img {
                                    width: 80px;
                                    margin: 0 auto;
                                    // border: 2px red dashed;
                                    margin-bottom: 10px;
                                }
                            }

                            .teamName {
                                display: flex;
                                flex-direction: column;
                                text-align: center;

                                h5 {
                                    font-family: var(--prak_med);
                                }
                            }

                        }

                    }

                    // end of global settings

                    //Past Games CSS

                    .pastGameCSS {
                        margin-top: 20px;
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 40px;

                        .gameInfo {
                            border: 2px solid var(--memphis_midnight);
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 0px;
                            border-bottom: 0px;
                            border-right: 0px;
                        }

                        .gameOpp {
                            border: 2px solid var(--memphis_midnight);
                            border-top-right-radius: 10px;
                            border-bottom: 0px;
                        }

                        .gameInfo,
                        .gameOpp {
                            width: 50%;
                        }


                        .resultsGameLeaders {
                            display: grid;

                            border-right: 0px;

                            align-content: space-evenly;

                            h4 {
                                margin-bottom: 15px;
                            }

                            .gameLeaderContainer {

                                .player {

                                    .rosterImage {
                                        background-color: #E3EAF5;
                                        padding-top: 10px;
                                        border-radius: .5rem;
                                        margin-bottom: 10px;
                                    }

                                    h5 {
                                        margin-bottom: 5px;
                                    }
                                }

                                h6 {
                                    margin-bottom: 10px;
                                }

                            }
                        }

                        .resultsGameContainer {
                            display: flex;
                            width: 100%;
                            text-align: center;

                            .resultsGameLeaders {
                                flex-basis: 50%;
                                border-bottom-left-radius: 10px;
                            }

                            .resultsVideos {
                                flex: 1 1 0%;
                                border-bottom-right-radius: 10px;

                                .videoTitle {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    .gametrackerLink {
                                        display: none;
                                    }
                                }

                            }
                        }
                    }



                    //Future Games CSS
                    .futureGameCSS {
                        margin-top: 20px;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        margin-bottom: 40px;


                        .gameInfo {
                            border-bottom-left-radius: 0;
                        }

                        .gameOpp {
                            border-left: 0;
                            border-right: 0;
                        }


                        //broadcast
                        .gameBroadPromosContainer {
                            // display: grid;
                            // order: 3;
                            // grid-column-start: 1;
                            // grid-column-end: 4;

                            display: flex;
                            order: 3;
                            grid-column-start: 1;
                            grid-column-end: 4;
                            flex-wrap: wrap;
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                            border-left: 2px solid var(--memphis_midnight);
                            border-bottom: 2px solid var(--memphis_midnight);
                            border-right: 2px solid var(--memphis_midnight);

                            .gDiv {
                                border: none;
                                border-left: 2px solid var(--memphis_midnight)
                            }

                            .gDiv:first-child {
                                border-left: none !important;
                            }

                            .gameBroadcast {
                                display: flex;
                                padding: 0px;
                                flex: 1 1 0%;

                                .promoBlock {
                                    display: flex;
                                    flex-direction: column;
                                    // align-items: center;
                                    padding: 20px;

                                    p {
                                        margin-left: 0px;
                                        margin-top: 10px;
                                    }

                                }
                            }

                            //theme night and game sponsor block (using desktop styling)
                            //Uniform Block (using desktop styling)

                        }

                        //CTA Buttons Block
                        .gameCTAButtons {
                            border-bottom-right-radius: 0;
                        }
                    }

                    //end of mapped data css
                }

            }


        }
    }
}
